import Footer from "@common/footer"
import Header from "@common/header"
import { THEME } from "../../configs/theme.js"
import { useEffect, useState } from "react"
import { getCountry } from "../../lib/navigation"
import { useRouter } from "next/router"
import { useTheme } from "next-themes"
import Sticky from "@common/sticky"
import Container from "@common/v2/container"

const HEADER_LINKS = THEME?.headerLinks
const FOOTER_LINKS = THEME?.footerLinks
const MOBILE_LINKS = THEME?.mobileLinks
const HEADER_CTA_LINKS = THEME?.headerCtaLinks

export default function LandingLayout({ children, getFooterLinks }) {
  const [footerLinks, setFooterLinks] = useState(FOOTER_LINKS)
  const router = useRouter()
  const country = getCountry(router)
  const { theme } = useTheme()
  const isDarkMode = theme == "dark"
  useEffect(() => {
    _setFooterLinks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, FOOTER_LINKS])

  async function _setFooterLinks() {
    const newFooterLinks = getFooterLinks
      ? await getFooterLinks(country, footerLinks)
      : footerLinks
    setFooterLinks(newFooterLinks)
  }

  return (
    <div className="flex flex-col min-h-screen bg-background">
      <Sticky stickyClasses=" border-b " classes="mb-3">
        <header className="backdrop-blur-md">
          <Container size="8xl">
            <Header
              siteName={THEME?.site}
              logoObj={THEME?.logo}
              links={HEADER_LINKS}
              primaryCtaLink={HEADER_CTA_LINKS && HEADER_CTA_LINKS[1]?.link}
              primaryCta={HEADER_CTA_LINKS && HEADER_CTA_LINKS[1].name}
              secondaryCtaLink={HEADER_CTA_LINKS && HEADER_CTA_LINKS[0].link}
              secondaryCta={HEADER_CTA_LINKS && HEADER_CTA_LINKS[0].name}
              mobileLinks={MOBILE_LINKS}
              isDarkMode={isDarkMode}
            />
          </Container>
        </header>
      </Sticky>

      <main className="flex-grow">{children}</main>

      {THEME?.footer?.visible == false ? null : (
        <footer>
          <Footer
            logoObj={THEME?.logo}
            footerSummary={THEME?.footerSummary}
            email={THEME?.email}
            company={THEME?.company}
            line1={THEME?.address && THEME?.address?.line1}
            line2={THEME?.address && THEME?.address?.line2}
            footerLinks={footerLinks}
            isDarkMode={isDarkMode}
          />
        </footer>
      )}
    </div>
  )
}

// context - read option 4 (bonus) here https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/
export const getLayout = (page) => <LandingLayout>{page}</LandingLayout>

// eslint-disable-next-line react/display-name
export const landingLayoutParams = (data) => (page) => {
  return <LandingLayout getFooterLinks={data?.getFooterLinks}>{page}</LandingLayout>
}
