/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, ReactNode, useRef } from "react"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react"
import Button from "@common/buttons/button"
import { Header, headerProps } from "@common/header/headerUnit"
import { joinClassNames } from "@common/lib/util"
import { ModalLayoutProps, ModalProps } from "@common/modal/types"
import Svg from "@common/svg"

// const transitionBackdropProps = {
//   enter: "ease-out duration-100",
//   enterFrom: "opacity-0",
//   enterTo: "opacity-100",
//   leave: "ease-in duration-100",
//   leaveFrom: "opacity-100",
//   leaveTo: "opacity-0",
//   as: Fragment,
// }
// const transitionChildProps = {
//   as: Fragment,
//   enter: "ease-out duration-100",
//   enterFrom: "opacity-0 scale-95",
//   enterTo: "opacity-100 scale-100",
//   leave: "ease-in duration-100",
//   leaveFrom: "opacity-100 scale-100",
//   leaveTo: "opacity-0 scale-95",
// }
export default function Modal({
  children,
  isOpen,
  closeOnOutsideClick = true,
  onOpen,
  onClose,
  crossButton = true,
}: ModalProps) {
  const [open, setOpen] = useState<boolean>(isOpen || false)
  // const focusRef = useRef(null)
  useEffect(() => {
    // null or undefined wont pass through to state
    if (typeof isOpen === "boolean") {
      setOpen(isOpen)
    }
  }, [isOpen])

  function closeModal() {
    setOpen(false)
  }

  // open/close callbacks
  useEffect(() => {
    open ? onOpen?.() : onClose?.()
  }, [open])

  const [trigger, _children] = React.Children.toArray(children).reduce<any>(
    (acc, child) => {
      if ((child as any).type.name == "ModalTrigger") {
        acc[0] = child
      } else {
        acc[1].push(child)
      }
      return acc
    },
    [null, []]
  )

  return (
    <>
      {trigger && <div onClick={() => setOpen(true)}> {trigger}</div>}
      <Dialog
        className="relative z-50"
        onClose={() => (closeOnOutsideClick ? closeModal() : {})}
        open={open}
        // initialFocus={focusRef}
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 w-screen bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 flex w-full items-center justify-center p-4 h-full">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-md border bg-background text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95 w-full md:w-fit"
          >
            {_children}
            {renderCrossButton()}
          </DialogPanel>
        </div>
      </Dialog>
    </>
  )
  // helper functions
  function renderCrossButton() {
    if (crossButton == false) return

    return (
      <div className="absolute top-0 right-0 z-10">
        <Button onClick={closeModal} variant="minimal" size="xs">
          <Svg name="cross" classes="h-4 w-4 text-secondary" />
        </Button>
      </div>
    )
  }
}

Modal.Trigger = function ModalTrigger({ children }: { children: ReactNode }) {
  return <>{children}</>
}
Modal.Layout = function ModalLayout({
  children,
  widthClasses,
  heightClasses,
  // autofocus = true,
}: ModalLayoutProps) {
  return (
    <div
      className={joinClassNames(
        "space-y-2 p-6 overflow-auto flex flex-col my-auto",
        widthClasses || "max-w-full",
        heightClasses || "max-h-[95vh]"
      )}
    >
      {/* {autofocus == false && <FocusShifter />} */}
      {children}
    </div>
  )
}

Modal.Header = function ModalHeader({
  children,
  variant = "h7",
  alignment = "left",
}: headerProps) {
  return (
    <Header.MainHeader variant={variant} alignment={alignment}>
      {children}
    </Header.MainHeader>
  )
}

Modal.Content = function ModalContent({ children }: { children: ReactNode }) {
  return <div className="flex-grow overflow-y-auto">{children}</div>
}

Modal.Footer = function ModalFooter({ children }: { children: ReactNode }) {
  return (
    <div className="flex flex-col md:flex-row gap-2 justify-end items-center">
      {children}
    </div>
  )
}
